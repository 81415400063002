/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable @typescript-eslint/restrict-template-expressions */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import { useQuery } from '@tanstack/react-query'
import Link from 'next/link'
import { useCallback, useMemo, useState } from 'react'
import { FiCheckCircle, FiCircle } from 'react-icons/fi'

import RemoteTable from '../../remote-table/RemoteTable'
import { fetchInquiryList } from '../api'

const InquiryRemoteTable = (props: any) => {
  const {
    controlledPageIndex: pageIndex,
    controlledPageSize: pageSize,
    setPageIndex,
    setPageSize,
  } = props

  const columns = useMemo(
    () => [
      {
        Header: 'Id',
        accessor: 'id',
        sortable: true,
        Cell: ({ value }: any) => (
          <Link href={`/inquiries/${value}`}>
            <a>
              <code className="truncate">{value.slice(0, 16)}</code>
            </a>
          </Link>
        ),
        width: 45,
      },
      {
        Header: '구분',
        accessor: 'type',
        sortable: true,
        width: 45,
      },
      {
        Header: '제목',
        accessor: 'title',
        sortable: true,
      },
      {
        Header: '회원',
        accessor: 'owner_user_id',
        sortable: true,
        Cell: ({ value }: any) => (
          <Link href={`/users/${value}`}>
            <a>
              <div className="truncate">{value}</div>
            </a>
          </Link>
        ),
        width: 30,
      },
      {
        Header: '답변 여부',
        accessor: (d: any) => (d.answer_check ? 'true' : 'false'),
        width: 30,
        Cell: ({ value }: any) => <div>{value === 'true' ? <FiCheckCircle /> : <FiCircle />}</div>,
      },
    ],
    [],
  )

  const [pageCount, setPageCount] = useState(0)

  const fetchData = useCallback(async ({ queryKey }: any) => {
    const [_key, { pageIndex, pageSize }] = queryKey
    const page = pageIndex + 1
    const response = await fetchInquiryList(page, pageSize)
    return response.data
  }, [])

  const { isLoading, data } = useQuery(['Inquirys', { pageIndex, pageSize }], fetchData, {
    onSuccess: data => {
      setPageCount(data?.pagination?.total_page)
    },
  })

  return (
    <div>
      <RemoteTable
        columns={columns}
        data={data?.data ?? []}
        loading={isLoading}
        controlledPageCount={pageCount}
        controlledPageIndex={pageIndex}
        controlledPageSize={pageSize}
        setControlledPage={setPageIndex}
        setControlledPageSize={setPageSize}
      />
    </div>
  )
}

export default InquiryRemoteTable
