/* eslint-disable @typescript-eslint/restrict-plus-operands */
/* eslint-disable @typescript-eslint/no-unsafe-call */
/* eslint-disable react/jsx-key */
import React, { FC, PropsWithChildren, ReactElement, useCallback, useMemo } from 'react'
import { FiChevronLeft, FiChevronRight, FiChevronsLeft, FiChevronsRight } from 'react-icons/fi'
import { TableOptions, useFlexLayout, usePagination, useSortBy, useTable } from 'react-table'

export type ITableProps<T extends Record<string, unknown>> = TableOptions<T>

export interface IRemoteTableProps<T extends Record<string, unknown>> extends ITableProps<T> {
  loading: boolean
  controlledPageCount: number
  controlledPageIndex: number
  controlledPageSize: number
  setControlledPage: (page: number) => void
  setControlledPageSize: (pageSize: number) => void
}

interface IPageWithText {
  activeClassNames?: string
  inactiveClassNames?: string
  children: any
  active?: boolean
  onClick: () => void
}

const PageWithText: FC<IPageWithText> = ({
  activeClassNames = 'btn border border-blue-600 btn-default bg-blue-500 hover:bg-blue-600 h-10 text-white',
  inactiveClassNames = 'btn border border-gray-200 btn-default bg-transparent hover:bg-gray-200 h-10 text-gray-900 dark:text-white',
  children,
  active = false,
  onClick,
}) => {
  return (
    <button
      onClick={active ? onClick : undefined}
      className={active ? activeClassNames : inactiveClassNames}>
      {children}
    </button>
  )
}

function RemoteTablePaginiation(props: any) {
  const {
    canNextPage,
    canPreviousPage,
    pageCount,
    pageIndex,
    pageOptions,
    pageSize,
    setControlledPage,
    setControlledPageSize,
  } = props

  const gotoPage = useCallback(
    (page: string | number) => {
      setControlledPage(page)
    },
    [setControlledPage],
  )
  const nextPage = useCallback(() => {
    setControlledPage(pageIndex + 1)
  }, [pageIndex, setControlledPage])
  const previousPage = useCallback(() => {
    setControlledPage(pageIndex - 1)
  }, [pageIndex, setControlledPage])

  return (
    <div className="flex flex-row items-center justify-between my-4">
      <div className="flex flex-wrap items-center justify-start space-x-2 pagination">
        <PageWithText onClick={() => gotoPage(0)} active={canPreviousPage}>
          <FiChevronsLeft className="text-lg" />
        </PageWithText>
        <PageWithText onClick={() => previousPage()} active={canPreviousPage}>
          <FiChevronLeft className="text-lg" />
        </PageWithText>
        <PageWithText onClick={() => nextPage()} active={canNextPage}>
          <FiChevronRight className="text-lg" />
        </PageWithText>
        <PageWithText onClick={() => gotoPage(pageCount - 1)} active={canNextPage}>
          <FiChevronsRight className="text-lg" />
        </PageWithText>
      </div>
      <div>
        <span>
          <strong>{pageOptions.length}</strong> 페이지 중 <strong>{pageIndex + 1}</strong> 페이지
          표시중
        </span>
      </div>
      <span>
        페이지로 직접 이동:
        <input
          type="number"
          defaultValue={pageIndex + 1}
          onChange={e => {
            const page = e.target.value ? Number(e.target.value) - 1 : 0
            gotoPage(page)
          }}
          style={{ width: '80px' }}
          className="ml-2 h-10 border-gray-200"
        />
      </span>{' '}
      <div>
        한 페이지에 표시할 행 수:
        <select
          value={pageSize}
          onChange={e => {
            setControlledPageSize(Number(e.target.value))
          }}
          className="ml-2 h-10 border-gray-200">
          {[10, 20, 30, 40, 50].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              {pageSize}
            </option>
          ))}
        </select>
      </div>
    </div>
  )
}

function RemoteTable<T extends Record<string, unknown>>(
  props: PropsWithChildren<IRemoteTableProps<T>>,
): ReactElement {
  const {
    columns,
    data,
    loading,
    controlledPageCount,
    controlledPageIndex,
    controlledPageSize,
    setControlledPage,
    setControlledPageSize,
  } = props

  const instance = useTable<T>(
    {
      columns,
      data: data ?? [],
      initialState: { pageIndex: 0 },
      manualPagination: true,
      manualSortBy: true,
      pageCount: controlledPageCount,
      useControlledState: state => {
        return useMemo(
          () => ({
            ...state,
            pageIndex: controlledPageIndex ?? 0,
            pageSize: controlledPageSize ?? 10,
          }),
          [state, controlledPageIndex, controlledPageSize],
        )
      },
    },
    useSortBy,
    usePagination,
    useFlexLayout,
  )

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    state: { pageIndex, pageSize },
  } = instance

  return (
    <div className="max-w-full block overflow-x-scroll overflow-y-hidden">
      <table {...getTableProps()} className="table">
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column: any) => (
                <th
                  className="flex flex-row justify-between"
                  {...column.getHeaderProps(column.sortable && column.getSortByToggleProps())}>
                  <div className="flex flex-row items-center justify-start py-2">
                    {column.render('Header')}
                    <span>{column.isSorted ? (column.isSortedDesc ? ' 🔽' : ' 🔼') : ''}</span>
                  </div>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="overflow-x-auto" {...getTableBodyProps()}>
          {page.map((row, i) => {
            prepareRow(row)
            return (
              <tr {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td {...cell.getCellProps()}>
                      <div className="py-2">{cell.render('Cell') as any}</div>
                    </td>
                  )
                })}
              </tr>
            )
          })}
          <tr>
            {loading ? (
              // Use our custom loading state to show a loading indicator
              <td colSpan={10000}>Loading...</td>
            ) : (
              <td colSpan={10000}>
                <div className="py-2">
                  Showing {page.length} of ~ {controlledPageCount * pageSize} results
                </div>
              </td>
            )}
          </tr>
        </tbody>
      </table>

      <RemoteTablePaginiation
        canNextPage={canNextPage}
        canPreviousPage={canPreviousPage}
        pageCount={pageCount}
        pageIndex={pageIndex}
        pageSize={pageSize}
        pageOptions={pageOptions}
        setControlledPage={setControlledPage}
        setControlledPageSize={setControlledPageSize}
      />
    </div>
  )
}

export default RemoteTable
