import React from 'react'

import InquiryRemoteTable from './InquiryRemoteTable'
import InquirySearchForm from './InquirySearchForm'

const InquiryListWithSearchForm = (props: any) => {
  const {
    controlledPageIndex: pageIndex,
    controlledPageSize: pageSize,
    setPageIndex,
    setPageSize,
  } = props

  return (
    <div>
      <InquirySearchForm />
      <InquiryRemoteTable
        controlledPageIndex={pageIndex}
        controlledPageSize={pageSize}
        setPageIndex={setPageIndex}
        setPageSize={setPageSize}
      />
    </div>
  )
}

export default InquiryListWithSearchForm
